const SPACE_DATA = {
		"u6995548":  [{space_js: "//swutws.cdymxikj.com/production/yt/static/sb/bqq/common/p/openjs/v.js", space_src: "baidujs", space_type: "inters", space_id: "u6995548"}],
		"u6995549":  [{space_js: "//swutws.cdymxikj.com/common/a/v/resource/udds/static/s-rd.js", space_src: "baidujs", space_type: "native", space_id: "u6995549"}],
		"u6995550":  [{space_js: "//swutws.cdymxikj.com/production/r/production/mluu/common/jj/resource/j_s.js", space_src: "baidujs", space_type: "native", space_id: "u6995550"}],
		"u6995551":  [{space_js: "//swutws.cdymxikj.com/site/sn/openjs/m/source/vvkk_kw.js", space_src: "baidujs", space_type: "native", space_id: "u6995551"}],
		"u6995552":  [{space_js: "//swutws.cdymxikj.com/production/t/production/o/static/nww/openjs/ll_ld.js", space_src: "baidujs", space_type: "native", space_id: "u6995552"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6995548"],
	Home_banner:  SPACE_DATA["u6995549"],
	Main_native:  SPACE_DATA["u6995550"],
	Classroom_native:  SPACE_DATA["u6995551"],
	Wikipedia_cat_native:  SPACE_DATA["u6995550"],
	Wikipedia_dog_native:  SPACE_DATA["u6995551"],
	Wikipedia_other_native:  SPACE_DATA["u6995552"],
	ConstellationList_banner:  SPACE_DATA["u6995549"],
	ConstellationList_native:  SPACE_DATA["u6995550"],
	ArticleDetail_inter:  SPACE_DATA["u6995548"],
	ArticleDetail_banner:  SPACE_DATA["u6995549"],
	ArticleDetail_native:  SPACE_DATA["u6995550"],
	PetDetail_inter:  SPACE_DATA["u6995548"],
	PetDetail_banner:  SPACE_DATA["u6995549"],
	PetDetail_native:  SPACE_DATA["u6995550"],
	Mood_dialog:  SPACE_DATA["u6995552"],
}
export const ALL_SPACE_MAP = {
    H5_YMMC_VO_7 : {
		Home_inter:  SPACE_DATA["u6995548"],
		Home_banner:  SPACE_DATA["u6995549"],
		Main_native:  SPACE_DATA["u6995550"],
		Classroom_native:  SPACE_DATA["u6995551"],
		Wikipedia_cat_native:  SPACE_DATA["u6995550"],
		Wikipedia_dog_native:  SPACE_DATA["u6995551"],
		Wikipedia_other_native:  SPACE_DATA["u6995552"],
		ConstellationList_banner:  SPACE_DATA["u6995549"],
		ConstellationList_native:  SPACE_DATA["u6995550"],
		ArticleDetail_inter:  SPACE_DATA["u6995548"],
		ArticleDetail_banner:  SPACE_DATA["u6995549"],
		ArticleDetail_native:  SPACE_DATA["u6995550"],
		PetDetail_inter:  SPACE_DATA["u6995548"],
		PetDetail_banner:  SPACE_DATA["u6995549"],
		PetDetail_native:  SPACE_DATA["u6995550"],
		Mood_dialog:  SPACE_DATA["u6995552"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6995548"],
		Home_banner:  SPACE_DATA["u6995549"],
		Main_native:  SPACE_DATA["u6995550"],
		Classroom_native:  SPACE_DATA["u6995551"],
		Wikipedia_cat_native:  SPACE_DATA["u6995550"],
		Wikipedia_dog_native:  SPACE_DATA["u6995551"],
		Wikipedia_other_native:  SPACE_DATA["u6995552"],
		ConstellationList_banner:  SPACE_DATA["u6995549"],
		ConstellationList_native:  SPACE_DATA["u6995550"],
		ArticleDetail_inter:  SPACE_DATA["u6995548"],
		ArticleDetail_banner:  SPACE_DATA["u6995549"],
		ArticleDetail_native:  SPACE_DATA["u6995550"],
		PetDetail_inter:  SPACE_DATA["u6995548"],
		PetDetail_banner:  SPACE_DATA["u6995549"],
		PetDetail_native:  SPACE_DATA["u6995550"],
		Mood_dialog:  SPACE_DATA["u6995552"],
    },
};
